import { useState, useEffect } from 'react';
import axios from 'axios';

import '../styles/ranking.css';

import { SpinnerCircular } from 'spinners-react';

function Ranking() {

    const [level, setLevel] = useState();
    const [time, setTime] = useState();
    const [killedMobs, setKilledMobs] = useState();
    const [killedPlayers, setKilledPlayers] = useState();
    const [fish, setFish] = useState();
    const [ores, setOres] = useState();

    useEffect(() => {

		axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/level`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setLevel(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/time`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setTime(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/mobs`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setKilledMobs(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/players`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setKilledPlayers(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/fish`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setFish(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/ranking/ores`
		}).then((result) => {
            const empty = 10 - result.data.length;
            for(var i=0; i<empty; i++) result.data.push({nick: "-",guild: "",level: 0,killedPlayers: 0,killedMobs: 0,playtime: 0,fish: 0,ores: 0})
            setOres(result.data);
		})

    }, []);

    const renderRanking = (title, param, array) => {

        return (
            <div className="table">
            <div className="table-header"> {title} </div>

            {array.map((user, index) => {
                const head = `https://minotar.net/avatar/${user.nick}/48`;

                return (
                    <div className="table-row" key={index}>
                        <div className="table-cell">#{index+1}</div>
                        <div className="table-cell"> <img src={head} alt=" " /> </div>
                        <div className="table-cell">
                            <div className="table-user-guild" style={{ 
                                backgroundColor: user.guild.length === 0 ? 'gray' : 'var(--light-red)' }}>  
                                {user.guild || "----"} 
                            </div>
                            <div className="table-user-nick"> {user.nick} </div>
                        </div>
                        <div className="table-cell">{param === 'playtime' ? (Math.round(user[param] / 60 / 60 * 10) / 10) + 'h' : user[param] }</div>
                    </div>
                )
            })}
        </div>
        )
    }

	return (
		<div className="ranking">

            {level && time && killedMobs && killedPlayers && fish && ores ? (
                <>
                    <p> Rankingi </p>

                    <div className="tables-container">

                        {renderRanking("Zdobyte Poziomy", "level", level)}
                        {renderRanking("Czas Gry", "playtime", time)}

                        {renderRanking("Zabite Moby", "killedMobs", killedMobs)}
                        {renderRanking("Zabici Gracze", "killedPlayers", killedPlayers)}

                        {renderRanking("Zlowione Ryby", "fish", fish)}
                        {renderRanking("Wykopane Rudy", "ores", ores)}

                    </div>
                </>
                )
            :
                <SpinnerCircular size="10%" color="#d2003b" speed="150" />
            }
		</div>
	);
}

export default Ranking;
